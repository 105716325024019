<template>
  <div style="width: 100%;height: 100vh;position: relative;overflow-y: auto">
    <div style="height: 284px;position: relative">
      <img src="../../../assets/share_top.png" style="width: 100%;height: 56px;object-fit: fill">
      <div class="share-collection-top">
        <div v-if="merchant_id != ''" class="merchant-container flex-row-center">
          <img v-if="merchantInfo.avatar_address" :src="'http://'+merchantInfo.avatar_address"
               style="border-radius: 50%;width: 32px;height: 32px;object-fit: cover;margin-right: 0.67rem">
          <p class="merchant-name">{{ merchantInfo.merchant_name }}配套音频小课</p>
        </div>
        <div style="margin-top: 25px;display: flex;flex-direction: row;align-items: flex-start;width: 100%;">
          <img v-if="collectionInfo.cover_pic_url" :src="collectionInfo.cover_pic_url"
               style="width: 96px;height: 96px;object-fit: cover">
          <div v-else-if="collectionInfo.album_contents && collectionInfo.album_contents.length>=4"
               class="collectionImg">
            <img v-for="i in 4" :key="i" :src="collectionInfo.album_contents[i-1].cover_pic_url">
          </div>
          <img v-else src="../../../assets/default_collecton.png"
               style="width: 96px;height: 96px;object-fit: cover">
          <div style="margin-left: 12px;">
            <p class="collection-name">
              {{ collectionInfo.collection_name }}</p>
            <p class="collection-intro">
              {{ collectionInfo.collection_intro }}</p>
            <div class="flex-row" style="flex-wrap: wrap;">
              <p class="counts-container" style="margin-right: 10px;margin-top: 6px;">共{{ albumCounts }}个专辑</p>
              <p class="counts-container" style="margin-right: 10px;margin-top: 6px;">共{{ allContentCount }}个内容</p>
              <p class="counts-container" style="margin-right: 10px;margin-top: 6px;">
                预估{{ Math.ceil(allContentCount / 5) }}个课时</p>
              <p class="counts-container" style="margin-top: 6px;">预计学习{{ Math.ceil(allContentCount / 5 / 2) }}周</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="collection-desc">
      <div class="tabLine">
        <div class="flex-row" style="align-items: center;position: relative;" @click="currentTab=1">
          <img src="../../../assets/share_collection_logo.png">
          <p style="margin-left: 10px;color: #333333;font-size: 16px;font-weight: bold;font-family: PingFang SC-Bold, PingFang SC; ">
            内容介绍</p>
          <div class="currentTab" v-if="currentTab===1"></div>
        </div>
        <div class="flex-row" style="align-items: center;margin-left: 20px;position: relative;" @click="currentTab=2">
          <img src="../../../assets/share_collection_logo.png">
          <p style="margin-left: 10px;color: #333333;font-size: 16px;font-weight: bold;font-family: PingFang SC-Bold, PingFang SC; ">
            内容列表</p>
          <div class="currentTab" v-if="currentTab===2"></div>
        </div>
      </div>
      <div v-if="currentTab===1">
        <div class="introPre">
          <div v-for="item in collectionInfo.simple_intro_list" :key="item">{{ item }}</div>
        </div>
      </div>
      <div v-if="currentTab===2">
        <div v-for="(album,index) in collectionInfo.album_contents" :key="index" class="collection-item11"
             @click="goAlbum(album)">
          <div>
            <img v-if="album.cover_pic_url && album.cover_pic_url !=''" :src="album.cover_pic_url"
                 class="cover-in-list" style="width:100px;height:100px">
            <img v-else src="../../../assets/defalut_album.png"
                 class="cover-in-list"
                 style="border-width: 0px;width:100px;height:100px">
          </div>
          <div style="flex: 1;width:100%;padding-left: 15px;box-sizing: border-box">
            <!--后副标题 = album_name_sub , 前副标题 = preposition_title-->
            <p class="preposition_title" v-if="album.preposition_title" style="font-size:14px;margin-bottom: 8px">
              {{ album.preposition_title }}</p>
            <p class="collection-album-title" style="font-size:18px">{{ album.album_name }}</p>

            <p class="album_name_sub" v-if="album.album_name_sub" style="font-size:14px;margin-top: 8px">
              {{ album.album_name_sub }}</p>

            <!--            <p class="collection-album-intro" style="font-size:14px">{{ album.simple_intro }}</p>-->
            <div
                style="width: 100%;display: flex;flex-direction: row;align-items: center;margin-top: 8px;font-size:14px">
              <div class="common-counts" style="margin-top: 0px;">
                <p v-if="album.album_songs">共{{ album.album_songs.length }}个内容</p>
                <p v-else>共0个内容</p>
              </div>
            </div>
            <!--            <div style="display: flex;flex-direction: row;flex-wrap: wrap;margin-top: 8px" >-->
            <!--              <div v-for="tag in album.album_tag_arr" :key="tag" class="common-tags">-->
            <!--                <p>{{ tag }}</p>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
      <p class="btn-purchase" @click="gotoPurchase" v-if="purchase_status==0">
        <span style="text-decoration: line-through;">(原价￥{{ collectionInfo.collection_price_show }})</span> ￥{{ price }}
        立即购买</p>
    </div>
  </div>
</template>

<script>
import {getCollectionInfo, getMerchantInfo} from '../../../api/keneng'
import '../../../assets/common/common.css'
import {getOpenId, setContentId, setMerchantId, setPurchaseType} from "../../../utils/store";

export default {
  name: "PurchaseCollectionLink",
  data() {
    return {
      purchase_status: 0,
      merchant_id: this.$route.query.merchant_id ? this.$route.query.merchant_id : '',
      collection_id: this.$route.query.collection_id,
      merchantInfo: '',
      collectionInfo: '',
      albumCounts: 0,
      allContentCount: 0,
      price: '',
      currentTab: 1
    }
  },
  mounted() {
    if (this.$route.query.purchase_status) {
      this.purchase_status = this.$route.query.purchase_status
    }
    this.fetchData()
  },
  methods: {
    gotoPurchase() {

      setMerchantId(this.merchant_id)
      setContentId(this.collection_id)
      setPurchaseType('collection')

      let open_id = getOpenId()
      if (false) {
        open_id = "oOh4h54l3ebT0mNFm-7nMRyb3Nnw"
      }
      if (open_id && open_id != '') {
        this.$router.replace({
          path: '/share-pay',
          query: {
            open_id: open_id
          }
        })
        return
      }

      const next_route = '/share-pay'
      const appId = 'wxda915e8dce4fe609'
      const redirectUrl = 'https://login.kenengxueyuan.com/link?next_route=' + next_route
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appId + '&redirect_uri=' + redirectUrl + '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect&connect_redirect=1'
    },
    fetchData() {
      
      if (this.merchant_id != ''){
        getMerchantInfo(this.merchant_id).then((res) => {
          this.merchantInfo = res.data.data
        })
      }
      
      getCollectionInfo(this.collection_id, this.merchant_id).then((res) => {
        this.collectionInfo = res.data.data
        document.title = '购买' + this.collectionInfo.collection_name

        if (this.merchant_id == ''){
          this.price = this.collectionInfo.collection_price
        }else{
          this.price = this.collectionInfo.mechant_content.price
        }

        this.collectionInfo.simple_intro_list = this.collectionInfo.simple_intro.split('\n')
        let album_contents = this.collectionInfo.album_contents
        this.albumCounts = album_contents.length
        for (let i = 0; i < this.albumCounts; i++) {
          let album = album_contents[i]
          this.allContentCount = this.allContentCount + album['album_songs'].length
        }
      })
    }
  }
}
</script>

<style scoped lang="less">

.preposition_title {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #5D5BB5;
  line-height: 22px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
}

.album_name_sub {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #4D4D4D;
  line-height: 22px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.btn-purchase {
  cursor: pointer;
  margin-top: 60px;
  text-align: center;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #FF5E45;
  border-radius: 24px 24px 24px 24px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
}


::-webkit-scrollbar {
  display: none;
}

.collection-desc {
  padding: 23px 28px;
  box-sizing: border-box;
  top: 260px;
  position: absolute;
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  min-height: 300px;
}


.share-collection-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  margin-top: -5px;
  position: relative;
  height: 228px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-image: url("../../../assets/share_top_bg.png");
  background-size: 100% 100%;
}

.merchant-container {
  background: #FCC52D;
  box-shadow: 0px 4px 20px 1px rgba(252, 197, 45, 0.16);
  border-radius: 20px 20px 20px 20px;
  padding: 4px 12px;
  box-sizing: border-box;
}

.merchant-name {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
}

.collection-name {
  color: white;
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500
}

.collection-intro {
  margin-top: 8px;
  line-height: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  font-size: 12px;
  color: white;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: 400
}

.counts-container {
  padding: 3px 10px;
  box-sizing: border-box;
  font-size: 10px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #FFFFFF;
}

.introPre {
  white-space: pre-wrap;
  word-wrap: break-word;
  width: 100%;
  margin-top: 24px;
  color: #454651;
  font-size: 14px;
  font-weight: 400;
  font-family: PingFang SC-Regular, PingFang SC;
  line-height: 28px;
  text-indent: 2em
}

.collectionImg {
  display: flex;
  flex-wrap: wrap;
  width: 96px;
  height: 96px;
  flex-shrink: 0;

  img {
    width: 50%;
    height: 50%;
  }

  img:nth-child(1) {
    border-radius: 12px 0 0 0;
  }

  img:nth-child(2) {
    border-radius: 0 12px 0 0;
  }

  img:nth-child(3) {
    border-radius: 0 0 0 12px;
  }

  img:nth-child(4) {
    border-radius: 0 0 12px 0;
  }

}

.tabLine {
  display: flex;
  align-items: center;
}

.currentTab {
  position: absolute;
  left: 30px;
  bottom: -12px;
  width: 58px;
  height: 2px;
  border-radius: 2px;
  background: rgb(108, 107, 191);
}

.collection-item11 {
  width: 96%;
  display: flex;
  margin-top: 24px;
}
</style>
